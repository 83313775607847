
header {
  background-color: #252f3e;
  color: white;
  padding: 0.36em 0.6em;

  div.language-toggles {
    float: right;
  }

  .language-toggles {
    text-align: right;

    .language-button {
      color: white;
      margin-left: .3em;
      background-color: #252f3e;
      border-width: 1;
      border-color: #252f3e;
      :focus {
        outline: none;
      }
    }
  }

  .header-container {
    display: block;
    max-width: 900px;
    margin: 0.8em auto;
    text-align: center;

    .header-logo {
      height: 100px;
      width: 110px;
      margin: auto;
    }

    .header-text {}
  }

  div.basic-nav {
    max-width: 50%;
    min-width: 50%;
    display: inline-block;
    // border-style: solid;
    // border-width: 5px;
    // border-color: white;
  }
  .basic-nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-decoration: none;
    }

    li a {
      float: left;
      text-align: center;
      text-decoration: none;
      color: white;
      display: inline;
      padding-left:10px;
      padding-right:10px;
    }

    li a:hover {
      background-color: #777;
    }

    li a.active-link {
      text-decoration:underline;
      outline: none;
    }
  }
}

.cn-maintenance-alert {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}