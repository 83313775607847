.usa-process-list {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2em;

  .usa-process-list__item {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 900px) {
  .usa-process-list {
    margin-left: 10px;
    margin-right: 10px;
  }
}